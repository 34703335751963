import React from "react";
import { Navigate } from "react-router-dom";
import { lazy } from "react";
import Loadable from "./Components/Loadable";
const LandPage = Loadable(lazy(() => import('./pages/1_landingpage/Home.js')));
const Auth = Loadable(lazy(() => import('./pages/2_Auth/Auth')));
const Dashboard = Loadable(lazy(() => import('./pages/5_ClientMenu')));
const CreateAccount = Loadable(lazy(() => import('./pages/2_Auth')));
const LandingPage = Loadable(lazy(() => import('./pages/3_Plane/index')));
const ResetPassword = Loadable(lazy(() => import('./pages/4_ResetPassword/index')));
const AboutUs = Loadable(lazy(() => import('./pages/6_AboutUs')));
const StripARNews = Loadable(lazy(() => import('./pages/9_News/StripARNews')));


const AgeVerificationPage = Loadable(lazy(() => import('./pages/8_AgeVerificationPage/AgeVerificationPage')));

const routes = (user) => [
    {
        path: "/",
        element: <LandPage />
    },
    {
        path: "/auth",
        element: !user ? <Auth /> : <Navigate to="/client" />
    },
    {
        path: "/client",
        element: <Dashboard />
    },
    {
        path: "/create",
        element: <CreateAccount />
    },
    {
        path: "/subscription",
        element: <LandingPage />
    },
    {
        path: "/reset",
        element: <ResetPassword />
    },
    {
        path: "/aboutus",
        element: <AboutUs />
    },
    {
        path: "/verification",
        element: <AgeVerificationPage />
    },

    {
        path: "/news",
        element: <StripARNews />
    },
];

export default routes;
