// src/store/activeImageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const activeImageSlice = createSlice({
  name: 'activeImage',
  initialState: null, // No image is active initially
  reducers: {
    setActiveImage: (state, action) => action.payload, // Set active image
    clearActiveImage: () => null, // Clear active image
  },
});

export const { setActiveImage, clearActiveImage } = activeImageSlice.actions;

export default activeImageSlice.reducer;
