import { Routes, Route } from "react-router-dom"; // This should be at the top
import { lazy } from "react"; // This should also be at the top
import Loadable from "./Components/Loadable"; // This too

import "./App.css"; // Make sure this comes after all imports
import "./index.css"; // This too

import routes from "./routes"; // Again, this should be after all the imports

const AppAppBar = Loadable(lazy(() => import('./pages/1_landingpage/modules/views/AppAppBar')));

function App() {
  const user = JSON.parse(localStorage.getItem("profile") ?? null);

  return (
    <>
      <AppAppBar />
      <Routes>
        {routes(user).map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
