import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amount: 0,  // Only the amount is stored in the state
};

const amountSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAmount: (state, action) => {
      // Action payload contains the amount
      state.amount = action.payload || 0;  // Update the amount, default to 0 if not provided
    },
  },
});

// Export the action to update the amount
export const { updateAmount } = amountSlice.actions;

// Export the reducer
export default amountSlice.reducer;
